.color-picker_root {
  height: 184px;
  width: 164px;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 6px 1px 0px 3px;
  animation: 0.25s color-picker_root-anim;
}

@keyframes color-picker_root-anim {
  0% {
    height: 0;
    width: 0;
  }
}

.color-picker_grid {
  width: 100%;
  /*justify-content: center;*/
  display: grid;
  grid-column-gap: 4px;
  grid-template-columns: repeat(4, 33px);
}

.color-picker_container {
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
