.pick-word_confirmed-view-container {
  height: calc(var(--vh, 1vh) * 57);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
}

.pick-word_root {
  display: flex;
  flex-direction: column;
  align-items: center;
  /*justify-content: space-between;*/
  /*max-height: 40vh;*/
  /*height: 100%;*/
}

.pick-word_sentence-puzzle-container {
  font-size: 20pt;
  line-height: 28pt;
  font-weight: normal;
  min-height: 75px;
}

.round-setup-pick-word_sentence-view {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  max-width: 80%;
  text-align: center;
}

.round-setup-pick-word_root {
}

input:focus {
  outline: none;
}

.round-setup-pick-word_floating-words {
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.round-setup-pick-word_title {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: calc(var(--vh, 1vh) * 12);
  width: 100%;
  color: #fff8d4;
  text-align: center;
  font-weight: bold;
  display: flex;
  justify-content: center;
  font-size: calc(var(--vh, 1vh) * 4);
  margin-top: calc(var(--vh, 1vh) * -4);
}

.round-setup-pick-word_title-red {
  background-image: url('../../../assets/doodleDash/catgoryRed.webp');
}

.round-setup-pick-word_title-blue {
  background-image: url('../../../assets/doodleDash/categoryBlue.webp');
}

.round-setup-pick-word_action-container {
  height: calc(var(--vh, 1vh) * 3);
  display: flex;
  justify-content: flex-end;
  padding-right: 7vw;
}

.round-setup-pick-word_floating-words-button {
  justify-content: center;
  height: calc(var(--vh, 1vh) * 11);
  width: calc(var(--vh, 1vh) * 37);
  font-size: calc(var(--vh, 1vh) * 5);
  margin: calc(var(--vh, 1vh) * 2);
  display: flex;
  align-items: center;
  background-image: url('../../../assets/doodleDash/btn.webp');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  animation: round-setup-pick-word_floating-words-button-enter 0.5s;
}

@keyframes round-setup-pick-word_floating-words-button-enter {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.round-setup-pick-word_floating-words-button-body {
  display: flex;
  width: 90%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.round-setup-pick-word_done-btn {
  background-image: url('../../../assets/doodleDash/btnSuccess.webp');
  height: calc(var(--vh, 1vh) * 11);
  width: calc(var(--vh, 1vh) * 37);
  font-size: calc(var(--vh, 1vh) * 5);
  margin: calc(var(--vh, 1vh) * 2);
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.round-setup-pick-word_done-check {
  background-image: url('../../../assets/doodleDash/checkSuccess.webp');
  width: calc(var(--vh, 1vh) * 5);
  height: calc(var(--vh, 1vh) * 5);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 0;
  top: -2vh;
}

.round-setup-pick-word_long-word {
  font-size: calc(var(--vh, 1vh) * 4);
}

.round-setup-pick-word_floating-words-content {
  margin: 2vh 0;
}

.round-setup-pick-word_button-grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pick-word_container {
  max-width: 78%;
}
