.single-canvas_root {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  width: 320px;
  max-height: 550px;
  height: 100%;
  flex-shrink: 1;
  overflow: hidden;
}

/*@media (max-height: 670px) {*/
/*  .single-canvas_root {*/
/*    height: 500px;*/
/*  }*/
/*}*/

/*@media (max-height: 610px) {*/
/*  .single-canvas_root {*/
/*    height: 450px;*/
/*  }*/
/*}*/

/*@media (max-height: 550px) {*/
/*  .single-canvas_root {*/
/*    height: 400px;*/
/*  }*/
/*}*/

.single-canvas_view-root {
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.single-canvas-voting_root {
  background-size: contain;
  background-repeat: no-repeat;
  height: 400px;
  width: 300px;
  overflow: hidden;
}
