.sketch_root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
  width: 100%;
  align-items: center;
  position: relative;
}

.sketch_drawing-single-canvas-container {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  flex-shrink: 1;
  height: 90%;
}

.sketch_bin-icon-position {
  position: absolute;
  top: 10px;
  right: 10px;
}

.sketch_bin-icon {
  width: 40px;
  height: auto;
}

.sketch_bin-popup-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.sketch_bin-popup {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 2px;
  box-shadow: rgb(0 0 0 / 14%) 4px 4px 0px 2px;
  height: 120px;
  width: 70px;
  justify-content: center;
  align-items: center;
  animation: sketch_bin-popup-enter 0.5s;
}

@keyframes sketch_bin-popup-enter {
  0% {
    height: 0;
  }
}

.sketch_bin-icon-open {
  width: 35px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../../assets/doodleDash/trash.webp');
}

.sketch_red-bg {
  margin-top: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: red;
  color: white;
  padding: 5px;
  border-radius: 5px;
  box-shadow: #9e1100 2px 2px 0px 2px;
}
.sketch_btn-container {
  display: flex;
  flex-shrink: 0;
  height: 10%;
  justify-content: center;
  align-items: center;
}
