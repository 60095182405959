.text {
  font-size: 30px;
  color: white;
  font-weight: 700;
  text-align: center;
  line-height: 42px;
  /*-webkit-text-stroke: 0.1px rgb(12, 12, 12);*/
}

.initialText {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  /*justify-content: center;*/
}

.cardsContainer {
  display: flex;
  align-self: center;
  grid-gap: 30px;
  margin: 20px 0 65px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100vw;
}

.commonWrapper {
  width: 100%;
  height: 100%;
  /*margin-left: auto;*/
  /*margin-right: auto;*/
  /*max-width: 1800px;*/
  /*min-width: 95%;*/
  margin-top: 10px;
  /*flex-direction: row;*/
  /*display: flex;*/
  /*min-height: calc(100% - 20px);*/
  /*z-index: 1;*/
}

.countDownWrapper {
  border-radius: 50%;
  background: #505050c7;
  padding: 15px;
  color: #fff;
  display: inline-block;
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 80px;
  font-size: 55px;
  font-family: monospace;
  font-weight: 400;
  border: 2px solid;
  text-shadow: 2px 2px #000000;
  margin-top: 10px;
  margin-right: auto;
  margin-left: auto;
}
.countDownWrapperStyled {
  border-radius: 50%;
  color: #000;
  display: inline-block;
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 85px;
  font-size: 55px;
  font-weight: 300;
  margin-top: 10px;
  margin-right: auto;
  margin-left: auto;
  box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.5);
}
