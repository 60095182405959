.app-body-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.app-title {
  font-size: 56px;
  text-align: center;
}

.app-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(var(--vh, 1vh) * 10);
  width: 100%;
}

.app-voice_chat_overlay_root {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0);
  z-index: 100;
}

@keyframes app-voice_chat_overlay_root_enter {
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes app-voice_chat_overlay_root_hide {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0);
  }
}

.app-voice_chat_overlay_bg {
  position: absolute;
  background-image: url('./assets/doodleDash/popup.webp');
  width: calc(var(--vh, 1vh) * 50);
  height: calc(var(--vh, 1vh) * 80);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.app-voice_chat_overlay_body {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 90%;
  height: 96%;
  padding: 2% 5%;
  text-align: center;
}

.app-voice_chat_overlay_root_show {
  animation: app-voice_chat_overlay_root_enter 0.6s forwards;
}

.app-voice_chat_overlay_root_hide {
  animation: app-voice_chat_overlay_root_hide 0.6s forwards;
}

.app-voice_chat_overlay_title {
  font-size: calc(var(--vh, 1vh) * 4);
  margin-top: calc(var(--vh, 1vh) * 4);
  text-align: center;
}

.app-voice_chat_overlay_subtitle {
  justify-self: flex-start;
  font-size: calc(var(--vh, 1vh) * 3);
  margin-top: calc(var(--vh, 1vh) * 4);
  text-align: center;
}

.app-voice_chat_icon {
  background-image: url('./assets/doodleDash/call.webp');
  height: 15vh;
  width: 15vh;
  background-repeat: no-repeat;
  background-size: contain;
}

.app-voice_chat_overlay_btn {
  height: calc(var(--vh, 1vh) * 10);
  margin-bottom: calc(var(--vh, 1vh) * 10);
}
