.OrientationMessage_root {
  z-index: 100000;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: calc(var(--vh, 1vh) * 5);
  text-align: center;
  flex-direction: column;
}

.OrientationMessage_iconContainer {
  background-size: contain;
  background-repeat: no-repeat;
  width: 20vw;
  height: 20vw;
}
