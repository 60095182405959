.submittable-input_root {
  background-image: url('../../assets/doodleDash/input.webp');
  background-size: contain;
  background-repeat: no-repeat;
  align-items: center;
  height: 76px;
  display: flex;
  position: relative;
  width: 315px;
  margin-bottom: 30px;
}

.submittable-input_input {
  border: none;
  background: transparent;
  font-size: calc(var(--vh, 1vh) * 4);
  width: 58%;
  margin-bottom: calc(var(--vh, 1vh) * 5);
  margin-top: calc(var(--vh, 1vh) * 5);
  color: #324663;
  margin-left: 10%;
}

.submittable-input_warning {
  position: absolute;
  bottom: -10%;
  color: red;
}

.submittable-input_submit {
  background-image: url('../../assets/doodleDash/submit.webp');
  width: 32%;
  height: 75%;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  right: -5px;
  transition: transform 0.3s, filter 0.3s;
}

.submittable-input_submit:active {
  transform: scale(0.9);
  filter: brightness(110%);
}
