.game-button_root {
  background-repeat: no-repeat;
  background-size: contain;
  width: calc(var(--vh, 1vh) * 40);
  height: calc(var(--vh, 1vh) * 11.1);
  font-size: calc(var(--vh, 1vh) * 4);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}

.game-button_root-sm {
  width: calc(var(--vh, 1vh) * 23);
  height: calc(var(--vh, 1vh) * 7);
  font-size: calc(var(--vh, 1vh) * 3);
}

.game-button_enabled {
  filter: brightness(100%);
  animation: game-button_enabled-anim 0.5s;
  transition: transform 0.3s, filter 0.3s;
}

.game-button_enabled:active {
  transform: scale(0.9);
  filter: brightness(110%);
}

@keyframes game-button_enabled-anim {
  0% {
    filter: brightness(50%);
  }
  50% {
    filter: brightness(115%);
  }
}

.game-button_disabled {
  filter: brightness(50%);
}

.game-button_red {
  background-image: url('../../assets/doodleDash/btnRed.webp');
}

.game-button_purple {
  background-image: url('../../assets/doodleDash/btnPurple.webp');
}

.game-button_green {
  background-image: url('../../assets/doodleDash/btnGreen.webp');
}

.game-button_blue {
  background-image: url('../../assets/doodleDash/btnBlue.webp');
}

.game-button_child-container {
  margin-top: calc(var(--vh, 1vh) * -1.5);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
