.active-drawing-simultaneous_content-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  width: 100%;
}

.active-drawing-simultaneous_sentence-puzzle-summary-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: calc(var(--vh, 1vh) * 2) 3vw;
  max-width: 80%;
}

.active-drawing-simultaneous_sentence-puzzle-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  /*max-width: 80%;*/
  text-align: center;
  font-size: 20pt;
  line-height: 28pt;
  font-weight: normal;
}
