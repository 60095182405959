.brush-popover_container {
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  position: absolute;
  top: 0;
  left: 0;
}

.brush-popover_icons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  max-height: calc(var(--vh, 1vh) * 15);
  padding-top: 10px;
}

.brush-popover_root {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
  background-size: contain;
  background-repeat: no-repeat;
  width: 43px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 6px 0px;
  padding-bottom: 1px;
  animation: 0.3s brush-popover_root-anim;
}

@keyframes brush-popover_root-anim {
  0% {
    height: 0;
  }
}

.brush-popover_icon-container {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 17px;
  left: 7px;
}

.brush_line {
  animation: 0.3s brush-popover_line-anim forwards cubic-bezier(0.42, 0, 0.52, 1.44);
  border-radius: 3px;
}

@keyframes brush-popover_line-anim {
  0% {
    width: 0;
  }
  100% {
    width: 30px;
  }
}

.brush_line-container {
  height: calc(var(--vh, 1vh) * 8);
  margin: 10px 0;
}
